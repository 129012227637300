<template>
  <v-dialog
    persistent
    v-model="dialogUpdateStatusActive"
    :width="screenWidth + '%'"
  >
    <div class="container-dialog">
      <close-dialog-component @closeDialog="closeDialog" />
      <p class="text-title mon-bold mb-9">{{ texts.orders.updateState.textTitle }}</p>
      <div>
        <v-select
          v-model="statusSelected"
          :label="texts.orders.updateState.textDisponibleState.label"
          :placeholder="texts.orders.updateState.textDisponibleState.placeholder"
          outlined
          color="#000000"
          dense
          class="global-inputs mon-regular"
          :items="aStatus"
          item-text="sDestinationStatusName"
          item-value="sDestinationStatusId"
          clearable
        ></v-select>
        <v-textarea
          v-model="statusDescription"
          class="global-inputs mon-regular mt-5"
          :label="texts.orders.updateState.textComments"
          :placeholder="texts.orders.updateState.textComments"
          outlined
          color="#000000"
          dense
          rows="4"
          maxLength="250"
        ></v-textarea>
        <!-- upload 1 -->
        <v-btn
          @click="$refs.inputUpload.click()"
          v-if="dataImgs.length == 0"
          class="button-add-document mon-bold mt-5"
          elevation="0"
        >
          <div>
            <v-icon
              color="#B2B2B2"
              size="60px"
            >
              mdi-image-plus
            </v-icon>
            <p
              class="text-button-document mon-semibold mt-2"
              v-html="texts.orders.updateState.textLabelImage"
            />
          </div>
          <input
            ref="inputUpload"
            style="display: none;"
            type="file"
            size="60"
            accept=".jpg, .jpeg, .png"
            multiple="true"
            @change="uploadFilesWarehouse"
          >
        </v-btn>
        <div
          v-else
          id="status-input-zone"
          class="mt-5"
        >
          <div
            v-for="(item, index) in dataImgs"
            :key="index"
            class="content-image-articles"
          >
            <v-btn
              @click="removeItem(item, index)"
              icon
              class="button-delete-item"
            >
              <v-icon
                size="25px"
                color="#D8686A"
              >
                mdi-delete
              </v-icon>
            </v-btn>
            <img
              :src="maskedFile(item)"
              alt=""
            >
          </div>
        </div>
      </div>
      <div v-if="isResponsive">
        <v-btn
          @click="sendUpdateStatus"
          class="button-primary mon-bold mt-5"
          elevation="0"
          :loading="bLoading"
          :disabled="!validateForm"
          style="width: 100%;"
        >
          {{ texts.orders.updateState.textButtonUpdate }}
        </v-btn>
        <v-btn
          class="button-secondary mon-regular mt-5"
          elevation="0"
          @click="closeDialog"
          style="width: 100%;"
        >
          {{ texts.orders.updateState.textButtonCancel }}
        </v-btn>
      </div>
      <div
        v-else
        class="display-flex align-items-center justify-content-flex-end mt-9"
      >
        <v-btn
          class="button-secondary mon-regular"
          elevation="0"
          @click="closeDialog"
        >
          {{ texts.orders.updateState.textButtonCancel }}
        </v-btn>
        <v-btn
          @click="sendUpdateStatus"
          class="button-primary mon-bold ml-5"
          elevation="0"
          :loading="bLoading"
          :disabled="!validateForm"
        >
          {{ texts.orders.updateState.textButtonUpdate }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "OrderDetailUpdateStatusLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      statusSelected: "",
      statusDescription: "",
      aStatus: [],
      screenWidth: 0,
      bLoading: false,
      dataImgs: [],
      isResponsive: false,
    };
  },
  beforeMount() {
    this.closeDialog();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    sendUpdateStatus: function () {
      this.bLoading = true;

      const payload = {
        sDescription: this.statusDescription,
        sPurchaseOrderStatusId: this.statusSelected,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/status/purchase-orders/${this.$route.params.id}`,
        payload,
        config
      )
        .then((response) => {
          if (this.dataImgs.length > 0) {
            this.uploadImgs(
              response.data.message,
              response.data.results.sPurchaseOrderId,
              response.data.results.sPurchaseOrderStatusRecordId
            );
          } else {
            this.bLoading = false;

            this.closeDialog();
            this.mixSuccess(response.data.message);
            // this.$router.push("/orders");
            this.$store.commit("refresher", true);
          }
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    uploadImgs: function (message, orderId, recordId) {
      let form = new FormData();

      this.dataImgs.forEach((element) => {
        form.append("image", element);
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${orderId}/records/${recordId}/images`,
        form,
        config
      )
        .then((response) => {
          this.bLoading = false;

          this.closeDialog();
          this.mixSuccess(message);
          this.$router.push("/orders");
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.bLoading = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    removeItem: function (item, index) {
      this.dataImgs.splice(index, 1);
    },
    maskedFile: function (file) {
      return URL.createObjectURL(file);
    },
    uploadFilesWarehouse: function (e) {
      const files = e.target.files;

      if (files.length > 0) {
        if (files.length < 16) {
          for (let i = 0; i < files.length; i++) {
            const element = files[i];
            this.dataImgs.push(element);
          }
        } else {
          this.mixError(this.texts.orders.updateState.textResponseLimitImages);
        }
      }
    },
    getStatus: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/status/purchase-orders/${this.dialogUpdateStatusArr.sPurchaseOrderStatusId}/adjacency`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        }
      )
        .then((response) => {
          this.aStatus = response.data.results.aAdjacency;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }

      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    closeDialog: function () {
      this.$store.commit("setDialogUpdateStatus", {
        active: false,
        arr: [],
      });
      this.dataImgs = [];
      this.statusSelected = "";
      this.statusDescription = "";
    },
  },
  computed: {
    dialogUpdateStatusActive: function () {
      return this.$store.state.dialogUpdateStatusActive;
    },
    dialogUpdateStatusArr: function () {
      return this.$store.state.dialogUpdateStatusArr;
    },
    selectLanguage: function () {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.statusSelected !== "" &&
        this.statusSelected !== null &&
        this.statusDescription !== ""
      );
    },
  },
  watch: {
    dialogUpdateStatusActive: function () {
      if (this.dialogUpdateStatusActive) {
        this.getStatus();
      }
    },
  },
};
</script>

<style scoped>
#status-input-zone {
  width: 100% !important;
  height: 275px !important;
  overflow: auto;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px dashed #70707070;
  border-radius: 20px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.button-delete-item {
  position: absolute;
  right: -1px;
  top: -1px;
  min-width: 40px !important;
  height: 40px !important;
  background-color: #ffffff99 !important;
  padding: 0px !important;
  border-radius: 20px;
}

.text-button-document {
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #cccccc;
  opacity: 1;
  text-transform: initial;
}

.button-add-document {
  width: 100% !important;
  height: 160px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px dashed #70707080;
  border-radius: 20px;
  opacity: 1;
}

.content-image-articles {
  width: 120px;
  height: 120px;
  margin: 5px;
  position: relative;
}

.content-image-articles img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image-articles img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
  }
}

.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 20px;
}

.text-description {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
}
</style>